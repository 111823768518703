import { PageRender } from "@/components/layout/frontend/page"
import globalConfig from "@/config/global"
import { de, findMissingTranslation, fr } from "@/dictionaries/check-translation"

/**
 * Page: index
 */
const Index: React.FC = () => {
  React.useEffect(() => {
    if (!globalConfig.inDev) return
    const missingKeysInDe: string[] = findMissingTranslation(de, fr)
    const missingKeysInFr: string[] = findMissingTranslation(fr, de)
    console.log("Clés manquantes dans de.json:", missingKeysInDe)
    console.log("Clés manquantes dans fr.json:", missingKeysInFr)
  }, [])

  return <PageRender pageKey="index" />
}
export default Index
