import { useForm, validator } from "@/components/form"
import { useMemoOnce } from "@/hooks/useMemoOnce"
import { useMaybeMe } from "@/store/auth/hooks"
import { dictionary } from "."

/**
 * useReservationForm
 */
export const useConcactForm = () => {
  const { _ } = useDictionary(dictionary("reservation", "contact"))

  const { min } = validator
  const maybeMe = useMaybeMe()
  return useForm({
    allowSubmitAttempt: true,
    values: useMemoOnce(() => {
      return {
        schoolName: "",
        schoolClass: "",
        schoolStreet: "",
        schoolZip: "",
        schoolCity: "",
        teacherName: maybeMe ? `${maybeMe.profile.firstname} ${maybeMe.profile.lastname}` : "",
        teacherEmail: maybeMe?.email ?? "",
        teacherPhone: "",
      }
    }),
    validate: validator({
      schoolName: [min(1, _("school-name-required"))],
      schoolClass: [min(1, _("school-class-required"))],
      schoolStreet: [min(1, _("school-street-required"))],
      schoolZip: [min(1, _("school-zip-required"))],
      schoolCity: [min(1, _("school-city-required"))],
      teacherName: [min(1, _("teacher-name-required"))],
      teacherEmail: [min(1, _("teacher-email-required"))],
      teacherPhone: [min(1, _("teacher-phone-required"))],
    }),
    onSubmit: F.ignore,
  })
}
