import { RouterPage, RouterPageKey, routerPages } from "@/routes/pages"

/**
 * first header menu
 */
export const menuHeader1: MenuLink[] = [
  { ...routerPages.index, name: "index" as const, exact: true },
  { ...routerPages.news, name: "news" as const },
  { ...routerPages.projects, name: "projects" as const },
  { ...routerPages.programs, name: "programs" as const },
  // { ...routerPages.offers, name: "offers" as const },
  { ...routerPages.contact, name: "contact" as const },
]

/**
 * second header menu
 */
export const menuHeader2 = [
  { ...routerPages.kulturrallye, name: "kulturrallye" as const },
  { ...routerPages.vpDays, name: "vpDays" as const },
]

export interface MenuLink extends RouterPage {
  name: RouterPageKey
  exact?: boolean
}
