import { useCookies } from "@/components/cookies"
import { Link } from "@/components/ui/link"
import { inDev } from "@/config/global"
import { createContextMapper } from "@/dictionaries/helpers"
import { useDictionary } from "@/dictionaries/hooks"
import { useAuthStore } from "@/store/auth"
import { useIsAdmin } from "@/store/auth/hooks"
import { format } from "date-fns"
import React from "react"
import { Container } from "./container"
import { Wrapper } from "./wrapper"

/**
 * dictionary src/dictionaries/fr/components/frontend/layout.json
 */
const dictionary = createContextMapper("components", "frontend", "layout")

/**
 * Footer
 */
export const Footer: React.FC = () => {
  const { _ } = useDictionary(dictionary("menu"))
  const copyrightYear = format(new Date(), "yyyy")
  const isAdmin = useIsAdmin()
  const { authenticated } = useAuthStore()
  const { setPreferences } = useCookies()
  return (
    <footer>
      <Wrapper>
        <Container
          x="sm"
          className="flex flex-col gap-3  md:flex-row md:justify-between  md:items-center py-5 text-gray text-xs font-normal"
        >
          <div
            className={cx(
              "flex max-md:flex-wrap text-gray md:divide-x",
              "[&>*]:flex [&>*]:items-center md:[&>:first-child]:pl-0 [&>*]:px-5 [&>*]:py-2",
              "[&>:hover]:text-orient [&>:hover]:underline [&>*]:transition-all"
            )}
          >
            <Link href="/legal-notice">{_("legalNotice")}</Link>
            <Link href="/data-protection">{_("dataProtection")}</Link>
            {/* <Link href="/accessibility">{_("accessibility")}</Link> */}
            <button onClick={() => setPreferences(true)}>{_("cookies-settings")}</button>

            {isAdmin && <Link href="/dashboard">{_("dashboard")}</Link>}
            {inDev && !authenticated && <Link href="/sign-in">Sign in</Link>}
          </div>
          <div className="uppercase">©{copyrightYear} Script</div>
        </Container>
      </Wrapper>
    </footer>
  )
}
