/// <reference types="vite-plugin-svgr/client" />
import Angebote from "@/assets/images/angebote.svg?react"
import { FooterPicture } from "@/components/frontend/drawing"
import { Container } from "@/components/layout/frontend/container"
import { PageRender } from "@/components/layout/frontend/page"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { byId } from "@/fns/byId"
import { usePromise } from "@/hooks/usePromise"
import { useResponsive } from "@/hooks/useResponsive"
import { service } from "@/services/programs/service"
import { getUniqueClasses } from "@/store/programs/helpers"
import { Program, localizeProgram } from "@/store/programs/localizers"
import { match } from "ts-pattern"
import { Item } from "./Item"
import { TableOfContent } from "./TableOfContent"

/**
 * dictionary src/dictionaries/en/pages/programs.json
 */
export const dictionary = createContextMapper("pages", "programs")

/**
 * Page: Programs
 */
const Programs: React.FC = () => {
  const [programs, setPrograms] = React.useState<ById<Program>>({})
  const [response, inProgress] = usePromise(async () =>
    match(await service.index())
      .with({ error: false }, ({ data }) => {
        setPrograms(byId(data.programs, localizeProgram))
        return { error: false } as const
      })
      .otherwise(({ error, code }) => ({ error, code } as const))
  )
  if (!inProgress && response.error) return null

  return (
    <PageRender pageKey="programs">
      <Render programs={programs} />
      <FooterPicture image={<Angebote />} />
    </PageRender>
  )
}
export default Programs

const Render: React.FC<{ programs: ById<Program> }> = ({ programs }) => {
  const { _ } = useDictionary(dictionary())

  const classes = React.useMemo(
    () =>
      pipe(
        programs,
        D.values,
        getUniqueClasses,
        A.sort((a, b) => b.localeCompare(a))
      ),
    [programs]
  )
  const [active, setActive] = React.useState(() => A.head(classes))
  const filtered = React.useMemo(
    () =>
      pipe(
        programs,
        D.values,
        A.filter(program => program.class === active),
        A.sort((a, b) => a.name.localeCompare(b.name))
      ),
    [active, programs]
  )

  // update active class on programs change
  React.useEffect(() => {
    pipe(classes, A.head, setActive)
  }, [classes])

  const media = useResponsive()

  const gridCx = "md:grid md:grid-cols-[1fr_100px_100px_100px] md:gap-4 xl:gap-8"
  return (
    <Wrapper>
      <Container x="sm">
        <div className="flex flex-col lg:grid lg:grid-cols-[325px_1fr] gap-5 py-5 md:py-10">
          <TableOfContent {...{ active, classes, setActive }} />
          <div className="flex flex-col w-full px-8 pt-8 pb-2 gap-8 border border-mercury">
            <h2 className="text-2xl font-bold">{active}</h2>
            <div className="flex flex-col divide-y divide-mercury">
              {media.min("md") && (
                <div className={cx("py-6", gridCx)}>
                  <div className="text-sm font-bold uppercase tracking-wider">{_(`branch`)}</div>
                  <div className="flex justify-center text-sm font-bold uppercase tracking-wider">
                    {_(`programs`)}
                  </div>
                  <div className="flex justify-center text-sm font-bold uppercase tracking-wider">
                    {_(`books`)}
                  </div>
                  <div className="flex justify-center text-sm font-bold uppercase tracking-wider">
                    {_(`pearltrees`)}
                  </div>
                </div>
              )}
              {A.map(filtered, program => (
                <Item
                  program={program}
                  key={program.id}
                  className={cx("flex flex-col md:items-center py-4", gridCx)}
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}
