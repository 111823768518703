/// <reference types="vite-plugin-svgr/client" />
import NewsPicture from "@/assets/images/news.svg?react"
import { Pagination } from "@/components/collection"
import { FooterPicture } from "@/components/frontend/drawing"
import { Container } from "@/components/layout/frontend/container"
import { PageRender } from "@/components/layout/frontend/page"
import { Wrapper } from "@/components/layout/frontend/wrapper"
import { usePaginable } from "@/hooks/usePaginable"
import { useData } from "@/hooks/usePromise"
import { useResponsive } from "@/hooks/useResponsive"
import { service } from "@/services/frontend/service"
import { Article, localizeArticle } from "@/store/frontend/localizers"
import { match } from "ts-pattern"
import { FilterByCategory } from "./FilterByCategory"
import { Item } from "./Item"

/**
 * dictionary src/dictionaries/en/pages/news.json
 * dictionary src/dictionaries/fr/pages/news.json
 * dictionary src/dictionaries/de/pages/news.json
 */
export const dictionary = createContextMapper("pages", "news")

/**
 * Page: News
 */
const News: React.FC = () => {
  const { _ } = useDictionary(dictionary())
  const media = useResponsive()

  const [articles] = useData(initialArticles, loadArticles)
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(null)
  const filtered = React.useMemo(
    () =>
      G.isNotNullable(selectedCategory)
        ? A.filter(articles, article => article.category?.id === selectedCategory)
        : articles,
    [articles, selectedCategory]
  )

  const [paginable, paginated] = usePaginable(
    filtered,
    1,
    media.min("xl") ? 12 : media.min("lg") ? 8 : 6
  )

  return (
    <>
      <PageRender pageKey="news" />
      <Wrapper margin="normal">
        <Container x="sm" className="flex flex-col gap-5 sm:gap-[30px]">
          {A.isNotEmpty(articles) ? (
            <>
              <FilterByCategory
                articles={articles}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-[30px]">
                {A.map(paginated, article => (
                  <Item key={article.id} article={article} />
                ))}
              </div>
              <Pagination {...paginable} />
            </>
          ) : (
            <>
              <div className="flex flex-col items-center justify-center min-h-[50vh] text-center px-4">
                <h2 className="text-2xl font-semibold text-orient">{_("no-articles-title")}</h2>
                <p className="mt-2 text-muted-foreground">{_("no-articles-secondary")}</p>
              </div>
            </>
          )}
        </Container>
      </Wrapper>
      <FooterPicture image={<NewsPicture />} />
    </>
  )
}
export default News

/**
 * loadArticles
 */
const loadArticles = async () =>
  match(await service.articles.index(["news", "hero"]))
    .with({ error: false }, ({ data }) => A.map(data.articles, localizeArticle))
    .otherwise(() => initialArticles)
const initialArticles: Article[] = []
