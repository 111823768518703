import React from "react"

/**
 * useGovbar
 * @param version - version of the govbar by default current version
 */
export const useGovbar = (version: "2-1-0" | "2-2-0" = "2-2-0") => {
  const [lang, setLang] = React.useState(document.documentElement.lang)
  const isGovbarVisible = useElementObserver(`#govbar`)

  React.useEffect(() => {
    const observer = new MutationObserver(() => setLang(document.documentElement.lang))
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["lang"],
    })
    return () => observer.disconnect()
  }, [])

  React.useEffect(() => {
    // append script to body if not present
    if (!document.getElementById("govbar-script")) {
      const script = document.createElement("script")
      script.id = `govbar-script-${version}`
      script.src = `//cdn.public.lu/skizz/govbar/${version}/govbar.js`
      script.async = true
      script.onload = () => window?.govbar?.()
      document.body.appendChild(script)
    } else window?.govbar?.()
    return () => {
      document.getElementById(`govbar-script-${version}`)?.remove()
      document.getElementById(`govbar`)?.remove()
    }
  }, [version, lang])

  React.useEffect(() => {
    const img = document.querySelector(`#govbar img`) as HTMLImageElement | undefined
    if (img) {
      const pageLocale = lang.split("-")[0] ?? "fr"
      const locales = ["fr", "de", "en"]
      if (locales.includes(pageLocale)) {
        img.src = `//cdn.public.lu/pictures/logos/gov/${pageLocale}/gov-light.png`
        img.srcset = `//cdn.public.lu/pictures/logos/gov/${pageLocale}/gov-light.png 1x, //cdn.public.lu/pictures/logos/gov/${pageLocale}/gov-light-2x.png 2x`
      }
    }
  }, [isGovbarVisible, lang])
}
declare global {
  interface Window {
    govbar?: () => unknown
  }
}

const useElementObserver = (selector: string) => {
  const [isVisible, setIsVisible] = React.useState(!!document.querySelector(selector))

  React.useEffect(() => {
    const observer = new MutationObserver(() => {
      const element = document.querySelector(selector)
      setIsVisible(!!element)
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [selector])

  return isVisible
}
